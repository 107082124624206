<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-title="this.$t('cip.desk.personalSharing.title.indexHeadTitle')"
                 :head-btn-options="headBtnOptions"
                 @head-remove="handleDelete"
                 @head-add="headAdd"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :head-title="this.$t('cip.desk.personalSharing.title.indexHeadTitle')"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @grid-row-detail-click="rowView"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @gird-handle-select-click="selectionChange"
                 @row-edit="rowEdit"
                 @row-del="rowDel">
    </grid-layout>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getList, remove} from "@/api/desk/personalSharing";
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 200,
        linklabel: "title",
        column: [
          {
            label: this.$t('cip.desk.personalSharing.field.title'),
            prop: "title",
            align: "left",
            overHidden: true,
            width: 240,
          },
          {
            label: this.$t('cip.desk.personalSharing.field.shareLink'),
            prop: "shareLink",
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.desk.personalSharing.field.shareTime'),
            prop: "createTime",
            align: "center",
            overHidden: true,
            width: 240,
          },
        ]
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.personalSharing_add, false),
        delBtn: this.vaildData(this.permission.personalSharing_delete, false),
        editBtn: this.vaildData(this.permission.personalSharing_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t('cip.desk.personalSharing.field.title'),
          prop: "title",
          span: 4,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.personalSharing.field.title'),
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permission.personalSharing_add) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.addBtn'),
            emit: "head-add",
            btnOptType: 'add',
          });
      }
      if (this.permission.personalSharing_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "head-remove",
            btnOptType: 'remove',
          });
      }
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permission.personalSharing_edit) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "row-edit",
          });
      }
      if (this.permission.personalSharing_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "row-del",
          })
      }
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    headAdd() {
      this.$router.push({
        path: `/personalSharing/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/personalSharing/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: `/personalSharing/edit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'view',
        }
      })
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionList = [];
      });
    },
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
  },
};
</script>
